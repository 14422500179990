/* global VERSION */
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

if (!DEVMODE) {
  Sentry.init({
    dsn: 'https://3cc327f7985f4ccb8af7083ec3709a6f@o1361812.ingest.sentry.io/6654054',
    release: `frontend:${VERSION}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
  });
}
