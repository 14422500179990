import { initi18 } from 'i18n/i18n';
import { initi18Events } from 'i18n/i18events';
import 'sentry';
import 'mocks/server';

if (DEVMODE) {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

async function init() {
  await initi18();
  initi18Events();

  const { initAuth } = await import('./auth');

  await initAuth();
}

init();
