import R from 'radio';
import { LANGUAGES } from './i18const';
import { i18 } from './i18n';

const i18changeLanguage = 'i18:change:language';
const i18getCurrentLanuage = 'i18:get:current:language';
const i18getLanguages = 'i18:get:language';

export function initi18Events() {
  // can't  use Const in these module
  // otherwise all const will be undefined
  R.app.on(i18changeLanguage, async (lang, reload = true) => {
    await i18.changeLanguage(lang);

    // use string not from const to avoid import of C
    const auth = await R.entity.request('auth:entity');

    if (auth.isAuthenticated()) {
      await auth.setLanguage(lang);
    }

    if (reload) {
      document.location.reload();
    }
  });

  R.app.reply(i18getLanguages, () =>
    Object.keys(LANGUAGES).map((code) => ({ code, name: LANGUAGES[code] }))
  );
  R.app.reply(i18getCurrentLanuage, () => i18.resolvedLanguage);
}
